import Base = require("Everlaw/Base");
import Rest = require("Everlaw/Rest");
import { BillingMode } from "Everlaw/BillingUtil";
import {
    MinimalOrganization,
    MinimalOrganizationParams,
    OrganizationId,
} from "Everlaw/MinimalOrganization";
import { TranslationProvider, ProviderDefault } from "Everlaw/ServiceProvider";

interface Contract {
    billingMode: BillingMode;
    partner: number;
}

interface OrganizationParams extends MinimalOrganizationParams {
    [otherParams: string]: unknown;
}

/**
 * In general, for any functionality supported by {@link MinimalOrganization}, you should prefer to
 * use that instead. The only {@link Organization} in the {@link Base} store is the current org,
 * which should generally be accessed through CURRENT_ORG (or useCurrentOrg in react) rather than
 * {@link Base#get}.
 */
export class Organization extends MinimalOrganization {
    override get className() {
        return "Organization";
    }
    emailDomains: EmailDomain[];
    salesforceAccountId: string;
    parentOrgId: OrganizationId | undefined;
    subOrgsEnabled: boolean;
    deleted: boolean;
    handlesNotifications: boolean;
    restrictDrive: boolean;
    contract: Contract;
    sensitiveDataOrg: boolean;
    cjisDataOrg: boolean;
    cjisFlagAllDbs: boolean;
    everlawInternalOrg: boolean;
    everlawAccessReportsEnabled: boolean;
    weekdayHours: string;
    saturdayHours: string;
    sundayHours: string;
    timezoneId: string;
    ecaAccessible: boolean;
    ecaMigratable: boolean;
    hideMessageContentInEmails: boolean;
    mfaRequired: boolean;
    termsDisabled: boolean;
    receiveCloudUploadAlerts: boolean;
    translationProvider: TranslationProvider | ProviderDefault;
    userInformationExportable: boolean;
    newDbOaAccessible: boolean;
    productionSharingSetting: ProductionSharingSetting;
    stagingDriveEnabled: boolean;

    constructor(params: OrganizationParams) {
        super(params);
    }
    override _mixin(params: OrganizationParams) {
        Object.assign(this, params); // take them all
    }
    /**
     * Builds a an array of projects involving this organization. Each key is a Project ID, and each
     * indicating whether that user is a project admin.
     */
    withProjIds(fPidArr: (projIds: OrgProjectGroup) => void) {
        Rest.get("/orgProjects.rest", { org: this.id }).then(fPidArr);
    }

    hasSupportHours() {
        return (
            !!this.weekdayHours || !!this.saturdayHours || !!this.sundayHours || !!this.timezoneId
        );
    }

    isMultiMatterModelEnabled(): boolean {
        return !(this.isTheUnassignedDatabasesOrg() || this.isAdminSuspended());
    }

    toggleSetting(setting: string, enabled: boolean): void {
        let flip: (enabled: boolean) => void;
        switch (setting) {
            case "hideMessageContentInEmails":
                flip = (enabled) => {
                    this.hideMessageContentInEmails = enabled;
                };
                break;
            case "mfaRequired":
                flip = (enabled) => {
                    this.mfaRequired = enabled;
                };
                break;
            case "receiveCloudUploadAlerts":
                flip = (enabled) => {
                    this.receiveCloudUploadAlerts = enabled;
                };
                break;
            case "userInformationExportable":
                flip = (enabled) => {
                    this.userInformationExportable = enabled;
                };
                break;
            case "newDbOaAccessible":
                flip = (enabled) => {
                    this.newDbOaAccessible = enabled;
                };
                break;
            default:
                // Invalid setting
                return;
        }
        Rest.post("toggleOrgSetting.rest", {
            org: this.id,
            enabled,
            setting,
        }).then(() => {
            flip(enabled);
            Base.publish(this);
        });
    }

    private static fetchParamsById(id: OrganizationId): Promise<OrganizationParams> {
        return Rest.get("/organization.rest", { orgId: id });
    }

    static async fetchById(id: OrganizationId): Promise<Organization> {
        return new Organization(await Organization.fetchParamsById(id));
    }
}

export type ProductionSharingSetting =
    | "DOWNLOAD_ONLY"
    | "EMAIL_AND_DOWNLOAD"
    | "LINK_AND_EMAIL_AND_DOWNLOAD";

export interface EmailDomain {
    name: string;
    autoAddUsers: boolean;
}

export interface OrgProjectGroup {
    orgProjects: number[];
    partnerProjects: number[];
}

export interface SamlInfo {
    updateTime: string;
    state: SamlState;
    mfaBypassEnabled: boolean;
    entityId: string;
}

export enum SamlState {
    DISABLED = "DISABLED",
    REQUIRED = "REQUIRED",
    OPTIONAL = "OPTIONAL",
}

export const CJIS_ORG_EXPLANATION =
    "the entire organization is flagged as containing CJIS data."
    + ` To unflag this organization, please contact Everlaw support at ${JSP_PARAMS.Help.supportEmail}`;

/**
 * On project-specific pages, represents the owning org of the project. On org-specific pages,
 * represents that org. Otherwise, undefined.
 */
export let CURRENT_ORG: Organization | undefined;

if (JSP_PARAMS.Organization?.current) {
    // For the time being, even though this is a singleton it's convenient to use the `Base` store
    // so that we can reuse the already-existing `Base.subscribe` and `Base.useStoreObject` for
    // refresh behavior. Eventually, once we have less react entry points on each page, we may want
    // to consider making this (as well as the current project, user, etc.) an actual react
    // `Context`.
    CURRENT_ORG = Base.set(Organization, JSP_PARAMS.Organization.current);
}

/**
 * Throws if called from a page without a current org context.
 */
export function useCurrentOrg(): Organization {
    if (!CURRENT_ORG) {
        throw new Error("Current org undefined");
    }
    // TODO: Eventually we may want to define a separate useState here rather than using
    //   `Base.useStoreObject(Base.globalStore(...`, so that we can remove Organization from the
    //   Base store entirely. For the time being, it's convenient to reuse this already existing
    //   framework.
    const org = Base.useStoreObject(Base.globalStore(Organization), CURRENT_ORG.id);
    if (!org) {
        throw new Error("Current org undefined");
    }
    return org;
}
